<template>
    <iframe v-if="key" :src="'https://direct.lc.chat/' + key + '/'" width="100%" :height="height" frameborder="0"></iframe>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            key:null
        }
    },
    computed: {
        height() {
            return window.innerHeight
        }
    },
    mounted() {
        userService.getLiveChatKey().then(({data}) => {
            this.key = data
        })
    }
}
</script>
