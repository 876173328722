import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://192.168.1.3:8000/api/';
const API_URL = 'https://admin.ethmining.info/api/';


// const API_URL = 'http://localhost:8000/api/';

class UserService {

  getHelpers() {
    return axios.get(API_URL + 'setting/helpers');
  }

  applyWithdraw(data) {
    return axios.post(API_URL + 'withdraw/apply',data, { headers: authHeader() })
  }

  exchangeEthToUsdt(data) {
    return axios.post(API_URL + "exchange/eth_to_usdt",data,{headers:authHeader()})
  }

  getEthToUsdtRate() {
    return axios.get(API_URL + "setting/eth_to_usdt_rate");
  }

  getTrxToUsdtRate() {
    return axios.get(API_URL + "setting/trx_to_usdt_rate");
  }

  getMinerIncomes() {
    return axios.get(API_URL + "setting/miner_income");
  }

  getUserBalance() {
    return axios.get(API_URL + "user/balance-reports", {headers:authHeader()});
  }

  getUserInfo() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getLiveChatKey() {
    return axios.get(API_URL + "setting/live_chat_key");
  }

  storeApprovalTransaction(data) {
    return axios.post(API_URL + 'store/approval/transaction',data,{headers:authHeader()});
  }
}

export default new UserService();