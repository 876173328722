<template>
    <div class="p-3">


        <div class="d-flex text-white">
            <div class="col-4 px-1" :key="index" v-for="(currency, index) in currencies">
                <div :class="{ 'active': selectedCurrency == index }" @click="chooseCurrency(index)"
                    class="px-1 type-btn d-flex align-items-center justify-content-center py-1">
                    <img class="me-2 w-25" :src="require('@/assets/' + currency.icon)" alt="">
                    <b>{{ currency.name }}</b>
                </div>
            </div>

            <div class="col-4 px-1">
                <div :class="{ 'active': selectedCurrency == -1 }" @click="chooseCurrency(-1)"
                    class="px-1 d-flex align-items-center type-btn justify-content-center py-1">
                    <img class="me-2 w-25" :src="require('@/assets/tether.png')" alt="">
                    <b>Cashout</b>
                </div>
            </div>
        </div>

        <p v-if="selectedCurrency == -1" class="mt-3 fw-bolder" style="line-height: 1;font-size: .9rem;">{{
            $t('withdraw_note') }}</p>

        <div class="bg-pri rounded">
            <div v-if="selectedCurrency == -1" class="mt-2">
                <div class="p-3 rounded text-white">
                    <div class="row">
                        <div class="col-4" :key="index" v-for="(type, index) in types" :index="index">
                            <div @click="selectedType = index" class="text-center rounded-pill type2-btn py-2"
                                :class="{ 'active': index == selectedType }">
                                {{ type.name }}
                            </div>
                        </div>
                    </div>
                    <div class="dotted-line mb-2 mt-3"></div>
                    <div>
                        <div class="d-flex justify-content-between mb-3">
                            <input v-model="withdrawal_address" type="text" class="transfer2-input py-2"
                                :placeholder="$t('please_enter_your_withdrawal_address')">
                            <div class="w-50 px-1 d-flex align-items-center justify-content-center py-2">
                                <img class="me-2 w-25" :src="require('@/assets/tether.png')" alt="">
                                <b>USDT</b>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <input v-model="withdrawal_amount" type="number" class="transfer2-input py-2"
                                :placeholder="$t('please_enter_your_withdrawal_amount')">
                            <div class="w-50 px-1 d-flex align-items-center justify-content-center py-2">
                                <img class="me-2 w-25" :src="require('@/assets/tether.png')" alt="">
                                <b>USDT</b>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="d-flex mt-4 justify-content-center">
                    <button class=" type-btn active text-white mt-2 py-2 px-5 rounded-pill">
                        {{ $t('confirm') }}
                    </button>
                </div> -->

            </div>


            <div class="mt-3 p-3 rounded" v-else>
                <div class="p-3 text-white rounded">
                    <div class="">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5 class="mb-0"><b>{{ $t('from') }}</b></h5>

                            <div class="w-25 bg-pri rounded px-1 d-flex align-items-center justify-content-center py-2">
                                <img class="me-2 w-50" :src="require('@/assets/' + currencies[selectedCurrency].icon)"
                                    alt="">
                                <h5 class="mb-0"><b>{{ currencies[selectedCurrency].name }}</b></h5>
                            </div>
                        </div>
                        <div class="line2"></div>

                        <div class="d-flex justify-content-center text-center mt-2">
                            <input style="font-size: 1.3rem;font-weight: 900;" v-model="currencyInput"
                                :placeholder="$t('please_enter_amount')" type="text" class="transfer-input text-center">
                        </div>
                    </div>
                </div>


                <div class="my-3 d-flex justify-content-center position-relative">
                    <button @click="all()" style="z-index: 2;" class="btn rounded-pill btn-exchange fw-bold text-white py-2 px-4">
                        <font-awesome-icon size="md" class="me-2" :icon="['fas', 'fa-right-left']" />
                        {{ $t('all') }}
                    </button>
                    <div class="line2 w-75" style="position: absolute;top:0;bottom: 0;margin: auto;z-index: 1;"></div>
                </div>



                <div class="p-3 text-white rounded text-center">
                    <input :value="exchangeValue" readonly type="text" class="transfer-input mb-2 text-center">
                    <div class="line2"></div>

                    <div class="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0"><b>{{ $t('to') }}</b></h5>
                        <div class="w-25 bg-pri rounded px-1 d-flex align-items-center justify-content-center py-2">
                            <img class="me-2 w-50" :src="require('@/assets/tether.png')" alt="">
                            <h5 class="mb-0"><b>USDT</b></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="d-flex justify-content-end mt-3 text-dark" v-if="currencies[selectedCurrency]">
            <h5 class="fw-bold">1 {{ currencies[selectedCurrency].name }} ≈ {{
                currencies[selectedCurrency].exchange_rate
            }}USDT</h5>
        </div>
        <div v-else>
            <div class="d-flex justify-content-between align-items-center mt-3 text-white">
                <h6 class="fw-bold mb-0 text-secondary">{{ $t('available') }} : USDT</h6>
                <h6 class="fw-bold mb-0 text-secondary text-end">{{ $t('handling') }} : <span class="text-white"> 25
                        USDT</span>
                </h6>
            </div>
        </div>


        <div @click="applyWithdraw()" class="rounded-pill w-50 mt-3 mx-auto fw-bolder text-center text-dark"
            style="background-image: linear-gradient(90deg,#4BB8FF,#8048FE)!important;padding-top: 10px;padding-bottom: 10px;">
            {{ currencies[selectedCurrency] ? 'Exchange' : 'Confirm' }}
        </div>

    </div>
</template>
<script>

import userService from '@/services/user.service'
export default {
    data() {
        return {
            selectedCurrency: 0,
            selectedType: 0,
            currencyInput: null,
            withdrawal_address: null,
            withdrawal_amount: null,
            currencies: [
                {
                    name: 'TRC',
                    icon: 'trx.png',
                    active: true,
                    exchange_rate: 0.009043,
                },
                {
                    name: 'ETH',
                    icon: 'eth.png',
                    active: false,
                    exchange_rate: 1826.98
                },
            ],
            types: [
                {
                    name: 'BSC',
                },
                {
                    name: 'ERC',
                },
                {
                    name: 'AVAX'
                }
            ]
        }
    },
    mounted() {
        if (this.$route.query.currency) {
            this.selectedCurrency = this.$route.query.currency
        }

        let that = this
        that.fetchEthToUsdtRate()
        that.fetchTrxToUsdtRate()

        setInterval(() => {
            that.fetchEthToUsdtRate()
            that.fetchTrxToUsdtRate()
        }, 1000 * 60);
    },
    computed: {
        exchangeValue() {
            return (this.currencyInput * this.currencies[this.selectedCurrency].exchange_rate).toFixed(2);
        },
        user() {
            return this.$store.state.auth.user
        }
    },
    methods: {
        all() {
            if (this.selectedCurrency == 1 && this.user) {
                this.currencyInput = this.user.balance
            } 
        },
        chooseCurrency(index) {
            this.selectedCurrency = index;
        },
        fetchEthToUsdtRate() {
            userService.getEthToUsdtRate().then(({ data }) => {
                this.currencies[1].exchange_rate = data
            })
        },
        fetchTrxToUsdtRate() {
            userService.getTrxToUsdtRate().then(({ data }) => this.currencies[0].exchange_rate = data)
        },

        applyWithdraw() {

            if (this.$store.state.auth.status.loggedIn) {
                if (this.selectedCurrency == 1) {
                    userService.exchangeEthToUsdt({
                        eth_amount: this.currencyInput,
                        usdt_amount: this.exchangeValue,
                    }).then(({ data }) => {
                        if (data.success) {
                            this.currencyInput = null;
                            this.$store.dispatch('auth/updateUserInfo', data.user)
                            alert('Exchage submitted!');
                        } else {
                            alert(data.message)
                        }
                    })
                } else {
                    userService.applyWithdraw({
                        withdrawal_address: this.withdrawal_address,
                        amount: this.withdrawal_amount,
                        type: this.selectedType
                    }).then(({ data }) => {
                        if (data.success) {
                            this.withdrawal_address = this.withdrawal_amount = null;
                            this.$store.dispatch('auth/updateUserInfo', data.user)
                            alert('Withdraw submitted!');
                        } else {
                            alert(data.message)
                        }
                    })
                }

            } else {
                alert('token expired');

            }

        }
    },
}

</script>
<style scoped>
.transfer-input {
    background-color: transparent;
    border: 0;
    outline: 0;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
}

.btn-exchange {
    background-image: linear-gradient(90deg, #8F60FF, #FF98D7) !important;
    border: 5px solid #4f4864;
}

.transfer2-input {
    background-color: transparent;
    border: 0;
    outline: 0;
    color: white;
    font-weight: bold;
    font-size: .9rem;
    width: 100%;
    border-bottom: 2px solid white;
}

.type-btn {
    position: relative;
    border: 2px solid #24365e;
    border-radius: 8px;
    background-clip: padding-box;
    padding: 10px;
    background: #24365e;
    color: white;
}

.type-btn.active {
    background: #5e72a6;
    border: 2px solid transparent;
}

.type-btn.active::after {
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    background: linear-gradient(90deg, #8F60FF, #FF98D7) !important;
    content: '';
    z-index: -1;
    border-radius: 8px;
}

.type2-btn {
    background-image: linear-gradient(90deg, #8F60FF, #FF98D7) !important;
    border: 5px solid #4f4864;

    opacity: .5;
}

.type2-btn.active {
    opacity: 1;
}
</style>

