<template>
    <div class=" p-3 px-4" style="height: 14vh;">
        <div class="d-flex px-2 align-items-center">
            <div class="col-2">
                <div class="avatar position-relative p-2 bg-dark rounded-circle d-flex align-items-center justify-content-center"
                    style="width: 45px;height: 45px;">
                    <font-awesome-icon size="xl" class="mb-1 text-white " :icon="['fas', 'fa-user']" />
                </div>
            </div>

            <div class="ms-2">
                <h6 class="mb-0 fw-bold">Account :</h6>
                <p style="word-break: break-all;font-weight: bold;" class="mb-0"> {{ displayAddress }}</p>
            </div>

        </div>
    </div>
    <div class="p-3 pt-0" style="margin-top: -30px;">
        <TodayIncomeComponent class="mt-2"></TodayIncomeComponent>
        <div class="mt-2">
            <div style="position: relative;z-index: 1;" class="bg-pri rounded px-3 py-2 mb-3">
                <div class="d-flex justify-content-between rounded align-items-center">
                    <div class="rounded px-1 d-flex align-items-center justify-content-center py-2">
                        <img class="me-2" style="width: 35px;" :src="require('@/assets/tether.png')" alt="">
                        <h3 class="mb-0" style="color: #000;"><b>USDT</b></h3>
                    </div>
                    <router-link :to="{
                        name: 'withdraw', query: {
                            currency: -1
                        }
                    }" class="text-decoration-none withdraw-btn">
                        <div class=" active rounded px-3 d-flex align-items-center">
                            <font-awesome-icon size="md" class="me-2 " :icon="['fas', 'fa-arrow-right-arrow-left']" />
                            {{ $t('withdrawal') }}

                        </div>
                    </router-link>
                </div>
                <div class="row mb-2">
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.ex_usdt_amount).toFixed(3) : '0.000' }}</div>
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.ex_usdt_frozen_amount).toFixed(3) : '0.000' }}</div>
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.ex_usdt_available_amount).toFixed(3) : '0.000' }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('statistics')
                    }}</div>
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('frozen') }}
                    </div>
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('available')
                    }}</div>
                </div>
            </div>

            <div style="position: relative;z-index: 1;" class="bg-pri rounded px-3 py-2 mb-3">
                <div class="d-flex justify-content-between rounded align-items-center">
                    <div class="rounded px-1 d-flex align-items-center justify-content-center py-2">
                        <img class="me-2" style="width: 35px;" :src="require('@/assets/eth.png')" alt="">
                        <h3 class="mb-0" style="color: #000;"><b>ETH</b></h3>
                    </div>
                    <router-link :to="{
                        name: 'withdraw', query: {
                            currency: 1
                        }
                    }
                        " class="text-decoration-none">
                        <div class="withdraw-btn rounded px-3 d-flex align-items-center">
                            <font-awesome-icon size="md" class="me-2 " :icon="['fas', 'fa-arrow-right-arrow-left']" />
                            {{ $t('swap') }}

                        </div>
                    </router-link>
                </div>
                <div class="row mb-2">
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.balance).toFixed(3) : '0.000' }}</div>
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.eth_frozen_amount).toFixed(3) : '0.000' }}</div>
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.eth_available_amount).toFixed(3) : '0.000' }}</div>
                </div>
                <div class="row">
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('statistics')
                    }}</div>
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('frozen') }}
                    </div>
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('available')
                    }}</div>
                </div>
            </div>

            <div style="position: relative;z-index: 1;" class="bg-pri rounded px-3 py-2 mb-3">
                <div class="d-flex justify-content-between rounded align-items-center">
                    <div class="rounded px-1 d-flex align-items-center justify-content-center py-2">
                        <img class="me-2" style="width: 35px;" :src="require('@/assets/trx.png')" alt="">
                        <h3 class="mb-0" style="color: #000;"><b>TRX</b></h3>
                    </div>
                    <router-link :to="{
                        name: 'withdraw', query: {
                            currency: 0
                        }
                    }
                        " class="text-decoration-none">
                        <div class="withdraw-btn rounded px-3 d-flex align-items-center">
                            <font-awesome-icon size="md" class="me-2 " :icon="['fas', 'fa-arrow-right-arrow-left']" />
                            {{ $t('swap') }}
                        </div>
                    </router-link>
                </div>
                <div class="row mb-2">
                     <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.trx_amount || 0).toFixed(3) : '0.000' }}</div>
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.trx_frozen_amount || 0).toFixed(3) : '0.000' }}</div>
                    <div class="col-4 text-center" style="color:#ff1c99!important;font-size:.8rem;font-weight:bold">
                        {{ user ? parseFloat(user.trx_available_amount).toFixed(3) : '0.000' }}</div>
                </div>
                <div class="row">
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('statistics')
                    }}</div>
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('frozen') }}
                    </div>
                    <div class="col-4 text-center" style="color:#869cda!important;font-size:.8rem;font-weight:bold">{{
                        $t('available')
                    }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TodayIncomeComponent from '@/components/share/TodayIncomeComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        TodayIncomeComponent
    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        getUserInfo() {
            if (this.user) {
                userService.getUserInfo().then((response) => {
                    this.$store.dispatch('auth/updateUserInfo', response.data.data)
                })
                .catch(err => {
                    console.log(err)
                });
            }
        }
    },
    computed: {
        displayAddress() {
            if (this.user) {
                return this.user.wallet_address
            }
            return null;
        },

        user() {
            return this.$store.state.auth.user
        }
    }
}
</script>
<style scoped>
.withdraw-btn {
    background: #1a253a !important;
    color: #fcceff;
    border-radius: 8px;
    position: relative;
    padding: 4px 0 4px 0;
}

.withdraw-btn::after {
    position: absolute;
    top: -2.5px;
    bottom: -2.5px;
    left: -2.5px;
    right: -2.5px;
    background: linear-gradient(270deg, #8F60FF, #FF98D7) !important;
    content: '';
    border-radius: 8px;
    z-index: -1;
}

.avatar::after {
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    background: linear-gradient(90deg, #8F60FF, #FF98D7) !important;
    content: '';
    border-radius: 50%;
    z-index: -1;
}
</style>